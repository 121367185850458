import { DEFAULT_USER_EMAIL_NOTIFICATIONS, NOTIFICATIONS } from "@/constants"
import { mapGetters } from "vuex"

export default {
  name      : "Notifications",
  components: {
  },
  props: {
    pEmailSubscriptions         : Array,
    pIsUpdatingEmailSubscription: Boolean
  },
  computed: {
    ...mapGetters({
      isMentionCommentsEnabled: "configurations/isMentionCommentsEnabled"
    }),
    emailSubscriptionsMap() {
      const emailSubscriptionsMap = new Map()
      this.pEmailSubscriptions.forEach(emailSubscription => {
        emailSubscriptionsMap.set(emailSubscription.notificationId, emailSubscription)
      })
      return emailSubscriptionsMap
    },
    reportNotifications() {
      return DEFAULT_USER_EMAIL_NOTIFICATIONS.REPORT.map(notificationId => {
        const emailSubscription = this.emailSubscriptionsMap.get(notificationId)
        return {
          id        : emailSubscription.id,
          key       : this.notificationsMap(emailSubscription.notificationId),
          subscribed: emailSubscription.subscribed
        }
      })
    },
    issueNotifications() {
      const filteredNotifications = this.isMentionCommentsEnabled
        ? DEFAULT_USER_EMAIL_NOTIFICATIONS.ISSUE
        : DEFAULT_USER_EMAIL_NOTIFICATIONS.ISSUE.filter(
          notificationId => notificationId !== NOTIFICATIONS.MENTION_COMMENTS
        )

      return filteredNotifications.map(notificationId => {
        const emailSubscription = this.emailSubscriptionsMap.get(notificationId)
        return {
          id        : emailSubscription.id,
          key       : this.notificationsMap(emailSubscription.notificationId),
          subscribed: emailSubscription.subscribed
        }
      })
    },
    systemWideNotifications() {
      return DEFAULT_USER_EMAIL_NOTIFICATIONS.SYSTEM_WIDE.map(notificationId => {
        const emailSubscription = this.emailSubscriptionsMap.get(notificationId)
        return {
          id        : emailSubscription.id,
          key       : this.notificationsMap(emailSubscription.notificationId),
          subscribed: emailSubscription.subscribed
        }
      })
    },
    notificationTables() {
      return [{
        notifications: this.reportNotifications,
        subHeader    : this.$t("1184")
      }, {
        notifications: this.issueNotifications,
        subHeader    : this.$t("1186")
      }, {
        notifications: this.systemWideNotifications,
        subHeader    : this.$t("1188")
      }]
    },
    headersForTable() {
      return this.$TABLES.NOTIFICATIONS.headers.map(header => {
        return {
          ...header, ...{
            text: this.$t(header.text)
          }
        }
      })
    }
  },
  emits  : ["updateEmailSubscription"],
  methods: {
    notificationsMap(notificationId) {
      const notifications = {
        [NOTIFICATIONS.NEW_REPORT]                               : this.$t("1185"),
        [NOTIFICATIONS.NEW_ISSUE_CREATED]                        : this.$t("1187"),
        [NOTIFICATIONS.DATA_EXPORT_CREATED]                      : this.$t("1189"),
        [NOTIFICATIONS.NEW_MESSAGE]                              : this.$t("1190"),
        [NOTIFICATIONS.REDO_TRANSLATION_COMPLETED]               : this.$t("1191"),
        [NOTIFICATIONS.HUMAN_TRANSLATION_JOB_DONE]               : this.$t("1192"),
        [NOTIFICATIONS.ISSUE_INVITATION]                         : this.$t("1193"),
        [NOTIFICATIONS.ISSUE_INVITATION_REMOVED]                 : this.$t("1194"),
        [NOTIFICATIONS.ISSUE_ASSIGNED]                           : this.$t("1195"),
        [NOTIFICATIONS.NEW_COMMENT]                              : this.$t("1196"),
        [NOTIFICATIONS.MENTION_COMMENTS]                         : this.$t("1388"),
        [NOTIFICATIONS.ACCESS_CHANGE]                            : this.$t("1594"),
        [NOTIFICATIONS.CONFIGURATION_CHANGE]                     : this.$t("1595"),
        [NOTIFICATIONS.ACCESS_TO_ISSUE_THROUGH_DOMAIN_CHANGE]    : this.$t("1596"),
        [NOTIFICATIONS.DUE_DATE_REMINDER_DUE_SOON_ASSIGNEE]      : this.$t("1844"),
        [NOTIFICATIONS.DUE_DATE_REMINDER_DUE_SOON_EDITING_RIGHTS]: this.$t("1845")
      }

      return notifications[notificationId]
    },
    handleUpdateEmailSubscription(emailSubscription) {
      const payload = {
        id        : emailSubscription.id,
        subscribed: !emailSubscription.subscribed
      }
      this.$emit("updateEmailSubscription", payload)
    }
  }
}